import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery/gallery"
import Teams from "../components/teams/web-team"
import SSS from "../components/sss/web-sss"

import tcddDev from "../assets/img/tcdd-dev.png"
import lines from "../assets/img/backgr.png"
import popupsmartDev from "../assets/img/popupsmart-dev-fluid.png"
import circle from "../assets/img/backgr2.png"
import lavarel from "../assets/img/lavarel.png"
import vue from "../assets/img/vue-core.png"
import weAreUsing from "../assets/img/weareusing.png"
import work from "../assets/img/work-preformance.png"
import webdev from "../assets/img/web-development.png"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/seo-companys"


class WebDev extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Web Development" description="We offer world-class web software development services that meet user needs, innovative, suitable for digital trends." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding" >
                            <h1 style={{ marginTop: 70 }}>Web <br/> Development</h1>
                            <span className="red-head">SERVICES</span>
                            <p style={{ marginTop: 25 }}>We offer world-class web & mobile software development services that meet user needs, innovative, suitable for digital trends.</p>
                            <input type="text" className="pink-input" placeholder="Enter your e-mail" value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="web-dev-button" onClick={(e) => this.showModal()}>Get your special offer</button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 40 }}><img alt="alt text" style={{ padding: 40 }} src={webdev} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Fill the form</h2>                               <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                            <div className="check-modal_container">
                            <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                                    <button  >SEND</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="web-dev container">
                    <div className="web-dev_brands py60">
                        <h2>All the brands that we serve.</h2>
                        <Companys />
                    </div>
                    <div className="development-studio py60">
                        <h2>Some of our featured campaigns.</h2>
                        <div className="tcdd-dev">
                            <img alt="alt text" src={tcddDev}></img>
                            <img alt="alt text" className="lines" src={lines}></img>
                            <h3>TCDD Transportation <br /> Website Development & UX Design</h3>
                            <p>We have developed a practical, functional and user-oriented website where <br/>TCDD passengers can easily implement their travel plans.</p>
                            <img alt="alt text" className="lavarel" src={lavarel}></img>
                        </div>
                        <div className="tcdd-dev" style={{ marginTop: 120 }}>
                            <img alt="alt text" src={popupsmartDev}></img>
                            <img alt="alt text" className="lines2" src={circle}></img>
                            <h3>Popupsmart Website & Mobile Application <br/>Development</h3>
                            <p>We have developed a functional website and mobile application where <br/>Popupsmart customers can easily create popups in a few steps.</p>
                            <img alt="alt text" className="lavarel" src={vue}></img>
                        </div>
                    </div>




                    <Gallery name="web" />




                    <div className="we-are-using  py60">
                        <h2 className="py60">Technologies we use.</h2>
                        <img alt="alt text" src={weAreUsing} />
                    </div>

                    <div className="onboarding py60">
                        <div className="row">
                            <div className="col-md-5">
                                <img alt="alt text" src={work} />
                                <h2>
                                    Why Brands <br/>Prefer Flatart for<br/> Web Development
                            </h2>
                                <p>Here are some of the features that we offer you to experience in case you choose us <br/>for your website and mobile application development needs.</p>
                            </div>
                            <div className="col-md-7 flatart-dev-list">
                                <ul>
                                    <li> <span className="tick"></span> Agile web design and development method</li>
                                    <li> <span className="tick"></span> Award-winning web solutions and websites </li>
                                    <li> <span className="tick"></span> Competitive prices for web design and development</li>
                                    <li> <span className="tick"></span> A cross-functional team of experts in the field </li>
                                    <li> <span className="tick"></span> In-house front-end professionals: Business analysts, UI / UX experts & designers</li>
                                    <li> <span className="tick"></span> Open and collaborative communication with customers</li>
                                    <li> <span className="tick"></span> Web design and web enhancements that are proven to be successful</li>
                                    <li> <span className="tick"></span> The quality test applied before implementing the project </li>
                                    <li> <span className="tick"></span> A web development process that reduces costs significantly</li>
                                    <li> <span className="tick"></span> Unique technology experience and expertise</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Our Web & Mobile Developers who can help you. </h2>
                    <Teams />
                </div>
                <div className="hiring" style={{ marginTop: -80 }}>
                    <h2>Frequently Asked Questions</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default WebDev
